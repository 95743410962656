import {useState} from 'react'
import { useSelector, useDispatch} from "react-redux"

import Box  from "@mui/material/Box"
import SwipeableDrawer  from "@mui/material/SwipeableDrawer"
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StopCircle from '@mui/icons-material/StopCircle';
import ClosedCaption from '@mui/icons-material/ClosedCaption';
import IconButton from '@mui/material/IconButton';


import {openMenu} from '../app/reducers/index';
import { useLazyDisconnectQuery, useSendRemoteFunctionMutation} from '../app/api';
import debounce from 'lodash.debounce'
import { CenterAlignedBox } from "../components/styled/containers";
import logo from '../assets/worldvue_banner_white.svg';

const paperStyle = {
   'width':'75vw',
   'maxWidth': '446px',
   'display':'flex', 
   'flexDirection': 'column',
   'background': 'rgba(51,51,51,1)'
}

const topSection = {
   'background': 'rgba(2,14,26,1)',
   'height': '75px'
}
const logoSection = {
   'background': 'rgba(36, 51, 65, 1)',
   'height': '175px',
   'display': 'flex'
}
const menuSection = {
   'flexGrow': 1,
   'display':'flex', 
   'flexDirection': 'column'
}

const SideBar = (props)=>{
   const dispatch = useDispatch();
   //Accordian logic
   const opened = useSelector((state)=>{
      return state.root.menuOpened
   })
   const serialNumber = useSelector((state)=>{
      return state.root.activeStb
   })
   const stbLabel = useSelector((state)=>{
      if(state.root.activeStb !== null){
         return state.root.devices[state.root.activeStb];
      }
      return null;
   })
   const [expanded, setExpanded] = useState(false);
   const handleChange = (panel, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };
   //diconnect handler
   const [disconnect] = useLazyDisconnectQuery();

   //action handler
   const [sendAction] = useSendRemoteFunctionMutation();
   const handleAction = debounce((action, event)=>{
      let payload = {'serialNumber': serialNumber, 'action': action};
      sendAction(payload).unwrap().then((result)=>{
         console.log(result)
      })
   }, 120)

   return <SwipeableDrawer PaperProps={{sx:paperStyle}} anchor='left' open={opened} onOpen={()=>{
      dispatch(openMenu(true))
   }} onClose={()=>{
      dispatch(openMenu(false))
   }}>
      <CenterAlignedBox sx={topSection}>
         <Box>{stbLabel}</Box>
      </CenterAlignedBox>
      <Box sx={logoSection}>
         <CenterAlignedBox sx={{'flexDirection': 'column', 'flexGrow': 1}}>
            <img src={logo} alt='Logo'/>
            <Typography>WEB REMOTE</Typography>
         </CenterAlignedBox>
      </Box>
      <Box sx={menuSection}>
         <MenuItem>
            <Box sx={{display:'flex', 'alignItems':'center'}}>Closed Caption</Box>
            <Box sx={{display:'flex', 'alignItems':'center'}}></Box>
            <Box sx={{display:'flex', 'alignItems':'center'}}>
               <IconButton size='small' onClick={handleAction.bind(this, {type:"TOGGLE_CC"})}>
                  <ClosedCaption />
               </IconButton>
            </Box>
         </MenuItem>
         <InputSelector expanded={expanded} onChange={handleChange} onInputSelect={handleAction} name='input_select' title='Change Input'/>
         {/* <ExpandableMenuItem expanded={expanded} onChange={handleChange} name='device_select'></ExpandableMenuItem> */}
         <MenuItem>
            <Box sx={{display:'flex', 'alignItems':'center'}}>End Device Control</Box>
            <Box sx={{display:'flex', 'alignItems':'center'}}></Box>
            <Box sx={{display:'flex', 'alignItems':'center'}}>
               <IconButton size='small' onClick={()=>{
                  disconnect().then((result)=>{
                     if(result.isSuccess){
                        window.location.reload();
                     }
                  })
               }}>
                  <StopCircle sx={(theme)=>({
                     'color': theme.palette.error.main
                  })}/>
               </IconButton>
            </Box>
         </MenuItem>
      </Box>
   </SwipeableDrawer>
}

const inputSelectorStyle =  {
   'display':'flex',
   'alignItems': 'center',
   'background': "rgba(61,61,61,1)",
   "padding": '0px 16px',
   "minHeight": '48px',
   'justifyContent': 'space-between',
   'borderTop': `1px solid rgba(51,51,51,1)`,
   '&:not(:last-child)': {
      borderBottom: 0,
   },
}



const InputSelector = (props)=>{
   return   <Accordion disableGutters elevation={0} square expanded={props.expanded === props.name} onChange={(event, isExpanded)=>{
                  console.log("Inside expandable menu item ")
                  props.onChange(props.name, isExpanded)
               }}>
               <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls={props.name+"bh-content"}
               id={props.name+"bh-header"}
               >
                  <Typography>
                     Change Input
                  </Typography>
                  {/* <Typography sx={{ color: 'text.secondary' }}>Test Summary</Typography> */}
               </AccordionSummary>
               <AccordionDetails sx={{
                  'padding': '0px',
               }}>
                  <Box onClick={props.onInputSelect.bind(this, {type:'SET_SOURCE', source:'hdmi1'})} sx={inputSelectorStyle}>HDMI 1</Box>
                  <Box onClick={props.onInputSelect.bind(this, {type:'SET_SOURCE', source:'hdmi2'})} sx={inputSelectorStyle}>HDMI 2</Box>
               </AccordionDetails>
            </Accordion>
}

const menuItemStyle =  {
      'background': "rgba(61,61,61,1)",
      "padding": '0px 16px',
      "minHeight": '48px',
      'display': 'flex',
      'justifyContent': 'space-between',
      'borderTop': `1px solid rgba(51,51,51,1)`,
      'borderBottom': `1px solid rgba(51,51,51,1)`,
      '&:not(:last-child)': {
         borderBottom: 0,
      },
   }


const MenuItem = (props)=>{
   return <Box sx={menuItemStyle}>
      {props.children}
   </Box>
}

export default SideBar