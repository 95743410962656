import { useSelector } from "react-redux";
import {FlexGrowGrid} from "../../components/styled/containers";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import NavigationControls from "./components/NavigationControls";
import UpDownButtons from './components/UpDownButtons';
import {useSendKeyMutation} from '../../app/api/index';
import debounce from 'lodash.debounce';
import AutoSizer from 'react-virtualized-auto-sizer'
//icons
import Home from '@mui/icons-material/Home';
import Mute from '@mui/icons-material/VolumeOff';
import ArrowBack from '@mui/icons-material/ArrowBack';
import RemoteKey from '../../components/controls/RemoteKey';


const style = {
   'display':'flex',
   'flexGrow':1,
   'justifyContent': 'space-between',
   // 'background': 'rgba(52,74,92,1)',
   'maxHeight': '48px',
   'borderRadius': '10px',
   'paddingTop': '8px',
   'paddingBottom': '8px',
   'marginBottom': '8px'
}

const iconButtonStyle = (theme)=>({
   'fontSize': '40px',
   'border': '1px solid ' + theme.palette.outlines.main,
   'borderRadius': '50%', 'padding': '8px'
})


const RemotePage = (props)=>{
   const serialNumber = useSelector((state)=>{
      return state.root.activeStb;
   })
   const [sendKey] = useSendKeyMutation();
   const handleButtonPress = debounce((key, event)=>{
      sendKey({key:key, serialNumber:serialNumber})
   }, 120)

   // return <FlexGrowColumnBox container sx={{border: "1px dashed white"}} id='remote-controls-container'>
   return <Grid direction='column' sx={{
      flexGrow:1,
      'paddingLeft':'48px', 
      'paddingRight':'48px',
      '@media (max-width: 358px)':{
         'paddingLeft':'24px',
         'paddingRight':'24px',
      },
      }} container id='remote-controls-container'>
     <Grid item sx={{
        // 'border': '1px solid white',
        'display':'flex',
        'flexGrow': 1
      }}>
      <Box sx={{display:'flex', 'flexGrow': 1}}>
            <AutoSizer id='nav-autosizer'>
            {({height, width})=>{
                  let length = width
                  if (width > height){
                     length = height
                  }
                  return <NavigationControls onButtonPress={handleButtonPress} height={length} width={length}/>
               }}
            </AutoSizer>
      </Box>
     </Grid>
      <Grid item>
         <Box sx={style}>
            <RemoteKey buttonKey='MUTE' onKeyPress={handleButtonPress}>
               <Mute sx={iconButtonStyle}/>
            </RemoteKey>
         </Box>
      </Grid>
     <Grid item>
        <Grid container>
           <FlexGrowGrid sx={{display:'flex'}}  item>
              <VolumeButtons onButtonPress={handleButtonPress} />
           </FlexGrowGrid>
           <FlexGrowGrid sx={{display:'flex'}} item>
               <Box sx={{flexGrow: 1, display:'flex', flexDirection: "column", justifyContent:'space-between'}}>
                  <RemoteKey  buttonKey='BACK' onKeyPress={handleButtonPress}>
                     <ArrowBack sx={iconButtonStyle}/>
                  </RemoteKey>
                  <RemoteKey buttonKey='PORTAL' onKeyPress={handleButtonPress}>
                     <Home sx={iconButtonStyle}/>
                  </RemoteKey>
               </Box>
           </FlexGrowGrid>
           <FlexGrowGrid sx={{display:'flex'}}  item>
              <ChannelButtons onButtonPress={handleButtonPress}/>
           </FlexGrowGrid>
        </Grid>
     </Grid>
   </Grid>
}

const VolumeButtons = (props)=>{
   return <UpDownButtons right name='VOL'
      upKey="VOL_UP"
      onUp={props.onButtonPress}
      downKey="VOL_DOWN"
      onDown={props.onButtonPress}/>
}

const ChannelButtons = (props)=>{
   return <UpDownButtons left name='CH'
      upKey="CH_UP"
      onUp={props.onButtonPress}
      downKey="CH_DOWN"
      onDown={props.onButtonPress}/>
}

export default RemotePage;